import { createSlice, PayloadAction, Update, isAnyOf } from '@reduxjs/toolkit'
import {
  CheckStatus,
  CheckUpdatedEvent,
  SaleGroupCategory,
  SaleGroupItem,
  SaleGroupUpdatedEvent,
} from '@ancon/wildcat-types'
import { UnknownAsyncThunkAction } from '@reduxjs/toolkit/dist/matchers'

import {
  PayAndGoConfirmCancelModalView,
  PayAndGoGroupParams,
  PayAndGoPaymentParams,
  PayAndGoPayMode,
  PayAndGoPayModeModalView,
  PayAndGoReducerState,
  PayAndGoSelectedItem,
} from '../types'
import getPayAndGoSelectedItemsFromMetadata from '../utils/getPayAndGoSelectedItemsFromMetadata'
import createSalesGroupItemFromCheckItem from '../utils/createSalesGroupItemFromCheckItem'
import isCheckItemPreviewed from '../../check/utils/isCheckItemPreviewed'

import {
  extractSelectedPayAndGoCheckItems,
  fetchPayAndGoGroup,
  fetchOwnCheckDetails,
  fetchPayAndGoOutlet,
  splitPayAndGoCheckItems,
  updateSelectedPayAndGoItemQuantity,
  clearAllPayAndGoItemSelections,
  extractPartialPayAndGoCheck,
  extractUnpaidPayAndGoCheckItems,
  mergeBackExtractedPayAndGoCheck,
} from './payAndGoThunks'
import {
  payAndGoSelectedItemEntityAdapter,
  payAndGoPaidItemEntityAdapter,
  payAndGoOngoingItemEntityAdapter,
  payAndGoUnpaidItemEntityAdapter,
} from './payAndGoEntityAdapters'

const initialPayAndGoState: PayAndGoReducerState = {
  virtualPOSClientId: null,
  groupParams: null,
  paymentParams: null,
  sessionId: null,
  originalCheckDetails: null,
  ownCheckDetails: null,
  mergeBackAt: null,
  outletDetails: null,
  payMode: PayAndGoPayMode.Full,
  payModeModalView: PayAndGoPayModeModalView.None,
  selectedItem: payAndGoSelectedItemEntityAdapter.getInitialState(),
  unpaidItem: payAndGoUnpaidItemEntityAdapter.getInitialState(),
  ongoingItem: payAndGoOngoingItemEntityAdapter.getInitialState(),
  paidItem: payAndGoPaidItemEntityAdapter.getInitialState(),
  splitItemModal: {
    isOpen: false,
    itemId: null,
  },
  requestIds: [],
  isGroupInitializing: true,
  isOutletInitializing: true,
  confirmCancelModalView: PayAndGoConfirmCancelModalView.None,
  isManualMergeBackInProgress: false,
}

const internalSelectedItemAdapterSelectors =
  payAndGoSelectedItemEntityAdapter.getSelectors()

const internalUnpaidItemAdapterSelectors =
  payAndGoUnpaidItemEntityAdapter.getSelectors()

const internalOngoingItemAdapterSelectors =
  payAndGoOngoingItemEntityAdapter.getSelectors()

const internalPaidItemAdapterSelectors =
  payAndGoPaidItemEntityAdapter.getSelectors()

function handleRequestId(
  state: PayAndGoReducerState,
  action: UnknownAsyncThunkAction,
) {
  const { requestId } = action.meta
  state.requestIds.push(requestId)

  // Keep the request ids list to a maximum of 25
  if (state.requestIds.length > 25) {
    state.requestIds.shift()
  }
}

function getNextTotalPrices(
  originalItem: SaleGroupItem,
  nextQty: number,
): Pick<
  SaleGroupItem,
  | 'totalPriceInclTax'
  | 'totalPriceExclTax'
  | 'totalDiscountAmountInclTax'
  | 'totalDiscountAmountExclTax'
> {
  const { currency } = originalItem.totalPriceInclTax

  const totalPriceInclTaxAmountPerQty =
    originalItem.totalPriceInclTax.amount / originalItem.quantity

  const nextTotalPriceInclTaxAmount = totalPriceInclTaxAmountPerQty * nextQty

  const totalPriceExclTaxAmountPerQty =
    originalItem.totalPriceExclTax.amount / originalItem.quantity

  const nextTotalPriceExclTaxAmount = totalPriceExclTaxAmountPerQty * nextQty

  const totalDiscountAmountInclTaxAmountPerQty =
    originalItem.totalDiscountAmountInclTax.amount / originalItem.quantity

  const nextTotalDiscountAmountInclTaxAmount =
    totalDiscountAmountInclTaxAmountPerQty * nextQty

  const totalDiscountAmountExclTaxAmountPerQty =
    originalItem.totalDiscountAmountExclTax.amount / originalItem.quantity

  const nextTotalDiscountAmountExclTaxAmount =
    totalDiscountAmountExclTaxAmountPerQty * nextQty

  return {
    totalPriceInclTax: {
      amount: nextTotalPriceInclTaxAmount,
      currency,
    },
    totalPriceExclTax: {
      amount: nextTotalPriceExclTaxAmount,
      currency,
    },
    totalDiscountAmountInclTax: {
      amount: nextTotalDiscountAmountInclTaxAmount,
      currency,
    },
    totalDiscountAmountExclTax: {
      amount: nextTotalDiscountAmountExclTaxAmount,
      currency,
    },
  }
}

const payAndGoSlice = createSlice({
  name: 'payAndGo',
  initialState: initialPayAndGoState,
  reducers: {
    setPayAndGoVirtualPOSClientId(state, action: PayloadAction<string | null>) {
      state.virtualPOSClientId = action.payload
    },
    setPayAndGoGroupParams(
      state,
      action: PayloadAction<PayAndGoGroupParams | null>,
    ) {
      state.groupParams = action.payload
    },
    setPayAndGoPaymentParams(
      state,
      action: PayloadAction<PayAndGoPaymentParams | null>,
    ) {
      state.paymentParams = action.payload
    },
    setPayAndGoSessionId(state, action: PayloadAction<string | null>) {
      state.sessionId = action.payload
    },
    setPayAndGoPayMode(state, action: PayloadAction<PayAndGoPayMode>) {
      state.payMode = action.payload
    },
    setPayAndGoSelectedItemQuantity(
      state,
      action: PayloadAction<PayAndGoSelectedItem>,
    ) {
      if (action.payload.selectedQuantity === 0) {
        payAndGoSelectedItemEntityAdapter.removeOne(
          state.selectedItem,
          action.payload.ids.join('_'),
        )
      } else {
        payAndGoSelectedItemEntityAdapter.upsertOne(
          state.selectedItem,
          action.payload,
        )
      }
    },
    setPayAndGoConfirmCancelModalView(
      state,
      action: PayloadAction<PayAndGoConfirmCancelModalView>,
    ) {
      state.confirmCancelModalView = action.payload
    },
    payAndGoEmitSaleGroupUpdatedEvent(
      state,
      action: PayloadAction<SaleGroupUpdatedEvent>,
    ) {
      const { source, destination, items, correlationId } = action.payload
      // Ignore our own events
      const ignore = correlationId && state.requestIds.includes(correlationId)

      if (!ignore) {
        const stateByCategory = {
          [SaleGroupCategory.UnpaidItems]: state.unpaidItem,
          [SaleGroupCategory.OngoingItems]: state.ongoingItem,
          [SaleGroupCategory.PaidItems]: state.paidItem,
        }

        const entityAdapterByCategory = {
          [SaleGroupCategory.UnpaidItems]: payAndGoUnpaidItemEntityAdapter,
          [SaleGroupCategory.OngoingItems]: payAndGoOngoingItemEntityAdapter,
          [SaleGroupCategory.PaidItems]: payAndGoPaidItemEntityAdapter,
        }

        const selectAllByCategory = {
          [SaleGroupCategory.UnpaidItems]:
            internalUnpaidItemAdapterSelectors.selectAll,
          [SaleGroupCategory.OngoingItems]:
            internalOngoingItemAdapterSelectors.selectAll,
          [SaleGroupCategory.PaidItems]:
            internalPaidItemAdapterSelectors.selectAll,
        }

        const stateBySource = stateByCategory[source]
        const stateByDestination = stateByCategory[destination]

        const selectAllBySource = selectAllByCategory[source]
        const selectAllByDestination = selectAllByCategory[destination]

        const sourceItems = selectAllBySource(stateBySource)
        const destinationItems = selectAllByDestination(stateByDestination)

        const changes = items.reduce<{
          updates: Update<SaleGroupItem>[]
          removals: string[]
          additions: SaleGroupItem[]
        }>(
          (acc, change) => {
            const sourceItem = change.sourceId
              ? sourceItems.find(i => i.ids.includes(change.sourceId))
              : null

            if (change.sourceId) {
              if (sourceItem && sourceItem.quantity - change.quantity > 0) {
                const nextQty = sourceItem.quantity - change.quantity

                acc.updates.push({
                  id: sourceItem.ids.join('_'),
                  changes: {
                    ids: [change.sourceId],
                    quantity: nextQty,
                    ...getNextTotalPrices(sourceItem, nextQty),
                  },
                })
              } else if (sourceItem) {
                acc.removals.push(sourceItem.ids.join('_'))
              }
            }

            if (change.destinationId) {
              const destinationItem = destinationItems.find(i =>
                i.ids.includes(change.destinationId),
              )

              if (destinationItem) {
                const nextQty = destinationItem.quantity + change.quantity

                acc.updates.push({
                  id: change.destinationId,
                  changes: {
                    ids: [change.destinationId],
                    quantity: nextQty,
                    ...getNextTotalPrices(destinationItem, nextQty),
                  },
                })
              } else if (sourceItem) {
                acc.additions.push({
                  ...sourceItem,
                  ids: [change.destinationId],
                  quantity: change.quantity,
                  ...getNextTotalPrices(sourceItem, change.quantity),
                })
              }
            }

            return acc
          },
          {
            updates: [],
            removals: [],
            additions: [],
          },
        )

        stateByCategory[source] = entityAdapterByCategory[source].updateMany(
          stateBySource,
          changes.updates,
        )

        stateByCategory[destination] = entityAdapterByCategory[
          destination
        ].updateMany(stateByDestination, changes.updates)

        entityAdapterByCategory[source].removeMany(
          stateBySource,
          changes.removals,
        )

        entityAdapterByCategory[destination].addMany(
          stateByDestination,
          changes.additions,
        )
      }
    },
    payAndGoEmitCheckUpdatedEvent(
      state,
      action: PayloadAction<CheckUpdatedEvent>,
    ) {
      const { checkId, check, correlationId } = action.payload
      // Ignore our own events
      const ignore = correlationId && state.requestIds.includes(correlationId)

      if (
        !ignore &&
        state.originalCheckDetails &&
        checkId === state.originalCheckDetails.id
      ) {
        state.originalCheckDetails = check

        const selectedItems = getPayAndGoSelectedItemsFromMetadata(
          check,
          state.sessionId,
        )

        const unpaidItems = internalUnpaidItemAdapterSelectors
          .selectAll(state.unpaidItem)
          .reduce<SaleGroupItem[]>((acc, unpaidItem) => {
            const checkItem = check.items.find(i =>
              unpaidItem.ids.includes(i.id),
            )

            // Remove unpaid items that are not in the check
            if (!checkItem) {
              return acc
            }

            // Update quantity if needed
            if (unpaidItem.quantity !== checkItem.quantity) {
              acc.push({
                ...unpaidItem,
                quantity: checkItem.quantity,
                ...getNextTotalPrices(unpaidItem, checkItem.quantity),
              })
            } else {
              acc.push(unpaidItem)
            }

            return acc
          }, [])

        state.selectedItem = payAndGoSelectedItemEntityAdapter.setAll(
          state.selectedItem,
          selectedItems,
        )

        state.unpaidItem = payAndGoUnpaidItemEntityAdapter.setAll(
          state.unpaidItem,
          unpaidItems,
        )

        const newItems = check.items.reduce<SaleGroupItem[]>(
          (acc, checkItem) => {
            if (
              isCheckItemPreviewed(checkItem.status) &&
              !unpaidItems.some(item => item.ids.includes(checkItem.id))
            ) {
              const salesGroupItem =
                createSalesGroupItemFromCheckItem(checkItem)

              acc.push(salesGroupItem)
            }

            return acc
          },
          [],
        )

        state.unpaidItem = payAndGoUnpaidItemEntityAdapter.addMany(
          state.unpaidItem,
          newItems,
        )
      }
    },
    setPayAndGoPayModeModalView(
      state,
      action: PayloadAction<PayAndGoPayModeModalView>,
    ) {
      state.payModeModalView = action.payload
    },
    setPayAndGoSplitItemModalOpen(state, action: PayloadAction<boolean>) {
      state.splitItemModal.isOpen = action.payload
    },
    setPayAndGoSplitItemModalItemId(
      state,
      action: PayloadAction<string | null>,
    ) {
      state.splitItemModal.itemId = action.payload
    },
    setPayAndGoOwnCheckStatus(state, action: PayloadAction<CheckStatus>) {
      if (state.ownCheckDetails) {
        state.ownCheckDetails.status = action.payload
      }
    },
    clearPayAndGoGroupParams(state) {
      state.groupParams = initialPayAndGoState.groupParams
    },
    clearPayAndGoGroupState(state) {
      state.originalCheckDetails = initialPayAndGoState.originalCheckDetails
      state.outletDetails = initialPayAndGoState.outletDetails

      state.payMode = initialPayAndGoState.payMode
      state.payModeModalView = initialPayAndGoState.payModeModalView

      state.selectedItem = initialPayAndGoState.selectedItem

      state.unpaidItem = initialPayAndGoState.unpaidItem
      state.ongoingItem = initialPayAndGoState.ongoingItem
      state.paidItem = initialPayAndGoState.paidItem

      state.splitItemModal = initialPayAndGoState.splitItemModal
    },
    clearPayAndGoPaymentParams(state) {
      state.paymentParams = initialPayAndGoState.paymentParams
    },
    clearPayAndGoPaymentState(state) {
      state.ownCheckDetails = initialPayAndGoState.ownCheckDetails
      state.mergeBackAt = initialPayAndGoState.mergeBackAt
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPayAndGoGroup.fulfilled, (state, action) => {
      const {
        originalCheck,
        virtualPos,
        unpaidItems,
        ongoingItems,
        paidItems,
      } = action.payload

      state.originalCheckDetails = originalCheck

      state.isGroupInitializing = false

      if (virtualPos != null) {
        state.virtualPOSClientId = virtualPos.id
      }

      const selectedItems = getPayAndGoSelectedItemsFromMetadata(
        originalCheck,
        state.sessionId,
      )

      state.selectedItem = payAndGoSelectedItemEntityAdapter.setAll(
        state.selectedItem,
        selectedItems,
      )

      state.unpaidItem = payAndGoUnpaidItemEntityAdapter.setAll(
        state.unpaidItem,
        unpaidItems,
      )

      state.ongoingItem = payAndGoOngoingItemEntityAdapter.setAll(
        state.ongoingItem,
        ongoingItems,
      )

      state.paidItem = payAndGoPaidItemEntityAdapter.setAll(
        state.paidItem,
        paidItems,
      )
    })

    builder.addCase(fetchOwnCheckDetails.fulfilled, (state, action) => {
      state.ownCheckDetails = action.payload
    })

    builder.addCase(fetchPayAndGoOutlet.fulfilled, (state, action) => {
      state.outletDetails = action.payload
      state.isOutletInitializing = false
    })

    builder.addCase(splitPayAndGoCheckItems.fulfilled, (state, action) => {
      if (action.payload != null) {
        const { updated, created } = action.payload

        const prevSelectedItems =
          internalSelectedItemAdapterSelectors.selectAll(state.selectedItem)

        const selectedItemUpdates = prevSelectedItems.reduce<
          Update<PayAndGoSelectedItem>[]
        >((acc, selectedItem) => {
          const updatedItem = updated.find(item =>
            selectedItem.ids.includes(item.id),
          )

          if (
            updatedItem &&
            updatedItem.quantity < selectedItem.selectedQuantity
          ) {
            acc.push({
              id: selectedItem.ids.join('_'),
              changes: {
                selectedQuantity: updatedItem.quantity,
              },
            })
          }

          return acc
        }, [])

        state.selectedItem = payAndGoSelectedItemEntityAdapter.updateMany(
          state.selectedItem,
          selectedItemUpdates,
        )

        const createdUnpaidItems = created.map(
          createSalesGroupItemFromCheckItem,
        )

        state.unpaidItem = payAndGoUnpaidItemEntityAdapter.addMany(
          state.unpaidItem,
          createdUnpaidItems,
        )

        const updatedUnpaidItems: Update<SaleGroupItem>[] = updated.map(
          checkItem => {
            const salesGroupitem = createSalesGroupItemFromCheckItem(checkItem)

            return {
              id: checkItem.id,
              changes: salesGroupitem,
            }
          },
        )

        state.unpaidItem = payAndGoUnpaidItemEntityAdapter.updateMany(
          state.unpaidItem,
          updatedUnpaidItems,
        )
      }
    })

    builder.addCase(
      updateSelectedPayAndGoItemQuantity.rejected,
      (state, action) => {
        const { prevItem, nextItem } = action.meta.arg

        if (nextItem != null && prevItem != null) {
          // Revert optimistically selected quantity
          payAndGoSelectedItemEntityAdapter.upsertOne(
            state.selectedItem,
            prevItem,
          )
        } else if (nextItem == null && prevItem != null) {
          // Add back optimistically removed item
          payAndGoSelectedItemEntityAdapter.addOne(state.selectedItem, prevItem)
        } else if (nextItem != null && prevItem == null) {
          // Remove optimistically added item
          payAndGoSelectedItemEntityAdapter.removeOne(
            state.selectedItem,
            nextItem.ids.join('_'),
          )
        }
      },
    )

    builder.addCase(clearAllPayAndGoItemSelections.pending, state => {
      state.selectedItem = initialPayAndGoState.selectedItem
    })

    builder.addCase(extractPartialPayAndGoCheck.fulfilled, (state, action) => {
      if (action.payload) {
        const { originalCheck, mergeBackAt, extractedCheck } = action.payload

        state.originalCheckDetails = originalCheck
        state.mergeBackAt = mergeBackAt
        state.ownCheckDetails = extractedCheck
      }
    })

    builder.addCase(mergeBackExtractedPayAndGoCheck.pending, state => {
      state.isManualMergeBackInProgress = true
    })
    builder.addCase(
      mergeBackExtractedPayAndGoCheck.fulfilled,
      (state, action) => {
        state.isManualMergeBackInProgress = false

        if (action.payload) {
          state.originalCheckDetails = action.payload
        }
      },
    )
    builder.addCase(mergeBackExtractedPayAndGoCheck.rejected, state => {
      state.isManualMergeBackInProgress = false
    })

    builder.addMatcher(
      isAnyOf(
        extractSelectedPayAndGoCheckItems.fulfilled,
        extractUnpaidPayAndGoCheckItems.fulfilled,
      ),
      (state, action) => {
        if (action.payload) {
          const { originalCheck, mergeBackAt, extractedCheck } = action.payload

          state.originalCheckDetails = originalCheck
          state.mergeBackAt = mergeBackAt
          state.ownCheckDetails = extractedCheck
        }
      },
    )

    // Store request IDs (correlation ID) to ignore our own events
    builder.addMatcher(
      isAnyOf(
        updateSelectedPayAndGoItemQuantity.pending,
        clearAllPayAndGoItemSelections.pending,
        extractSelectedPayAndGoCheckItems.pending,
        extractUnpaidPayAndGoCheckItems.pending,
        extractPartialPayAndGoCheck.pending,
        mergeBackExtractedPayAndGoCheck.pending,
      ),
      handleRequestId,
    )
  },
})

const payAndGoReducer = payAndGoSlice.reducer

export const {
  setPayAndGoVirtualPOSClientId,
  setPayAndGoGroupParams,
  setPayAndGoPaymentParams,
  setPayAndGoSessionId,
  setPayAndGoPayMode,
  setPayAndGoSelectedItemQuantity,
  payAndGoEmitSaleGroupUpdatedEvent,
  payAndGoEmitCheckUpdatedEvent,
  setPayAndGoPayModeModalView,
  setPayAndGoSplitItemModalOpen,
  setPayAndGoSplitItemModalItemId,
  setPayAndGoOwnCheckStatus,
  setPayAndGoConfirmCancelModalView,
  clearPayAndGoGroupParams,
  clearPayAndGoGroupState,
  clearPayAndGoPaymentParams,
  clearPayAndGoPaymentState,
} = payAndGoSlice.actions

export default payAndGoReducer
