/* NOTE: All paths should end with either 'details' or 'list' */

const media = {
  list: 'media',
  byId: {
    details: 'media/{mediaId}',
  },
}

const core = {
  clientContexts: {
    list: 'clientContexts',
  },
  identityUsers: {
    verifyEmail: {
      list: 'identityUsers/verifyEmail',
    },
  },
  tenants: {
    byId: {
      preOrders: {
        settings: {
          list: 'tenants/{tenantId}/preOrderSettings',
        },
      },
    },
  },
  outlets: {
    nearby: {
      list: 'outlets/nearby',
    },
    search: {
      list: 'outlets/search',
    },
    byId: {
      details: 'outlets/{outletId}',
      products: {
        byId: {
          details: 'outlets/{outletId}/products/{productId}',
        },
      },
      checkouts: {
        list: 'outlets/{outletId}/checkouts',
        wayOfPayments: {
          list: 'outlets/{outletId}/checkouts/paymentMethods',
        },
        byId: {
          details: 'outlets/{outletId}/checkouts/{checkoutId}',
          applyDiscount: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/applyDiscount',
          },
          summary: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/summary',
          },
          adyen: {
            payments: {
              list: 'outlets/{outletId}/checkouts/{checkoutId}/adyen/payments',
            },
            paymentDetails: {
              list: 'outlets/{outletId}/checkouts/{checkoutId}/adyen/payments/details',
            },
            paymentMethods: {
              list: 'outlets/{outletId}/checkouts/{checkoutId}/adyen/paymentMethods',
            },
          },
          cancel: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/paymentCancel',
          },
          invoice: {
            payments: {
              list: 'outlets/{outletId}/checkouts/{checkoutId}/invoice/payments',
            },
          },
          status: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/status',
          },
          penguinLockersMetadata: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/penguinLockersMetadata',
          },
        },
      },
      tables: {
        byId: {
          details: 'outlets/{outletId}/tables/{tableId}',
        },
        suggestions: {
          list: 'outlets/{outletId}/tables/suggestions',
        },
      },
      tickets: {
        byId: {
          markAsPickedUp: {
            list: 'outlets/{outletId}/tickets/{ticketId}/markAsPickedUp',
          },
          ticketItemsStatus: {
            list: 'outlets/{outletId}/tickets/{ticketId}/ticketItemsStatus',
          },
        },
      },
      preOrderCheckouts: {
        byId: {
          activate: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/preOrders/activate',
          },
          attendeeSignIn: {
            list: 'outlets/{outletId}/checkouts/{checkoutId}/preOrders/signIn',
          },
          attendees: {
            byId: {
              details:
                'outlets/{outletId}/checkouts/{checkoutId}/preOrders/attendees/{attendeeId}',
              confirm: {
                list: 'outlets/{outletId}/checkouts/{checkoutId}/preOrders/attendees/{attendeeId}/confirm',
              },
            },
          },
        },
        createGroupOrder: {
          list: 'outlets/{outletId}/checkouts/groupPreOrders',
        },
      },
      preOrders: {
        byId: {
          attendees: {
            byId: {
              details:
                'outlets/{outletId}/preOrders/{preOrderId}/attendees/{attendeeId}',
            },
          },
        },
      },
      integrations: {
        activeAdyenIntegration: {
          list: 'outlets/{outletId}/adyenIntegrations/active',
        },
      },
      checks: {
        list: 'outlets/{outletId}/checks',
        byId: {
          details: 'outlets/{outletId}/checks/{checkId}',
          items: {
            move: {
              list: 'outlets/{outletId}/checks/{checkId}/items/move',
            },
            split: {
              list: 'outlets/{outletId}/checks/{checkId}/items/split',
            },
          },
          paymentMethods: {
            online: {
              list: 'outlets/{outletId}/checks/{checkId}/paymentMethods/online',
            },
          },
          payments: {
            online: {
              list: 'outlets/{outletId}/checks/{checkId}/payments/online',
              details: {
                list: 'outlets/{outletId}/checks/{checkId}/payments/online/details',
              },
            },
            byId: {
              sendReceipt: {
                list: 'outlets/{outletId}/checks/{checkId}/payments/{paymentId}/sendReceipt',
              },
              downloadReceipt: {
                list: 'outlets/{outletId}/checks/{checkId}/payments/{paymentId}/downloadReceipt',
              },
            },
          },
          metadata: {
            list: 'outlets/{outletId}/checks/{checkId}/metadata',
          },
          extractCertainItems: {
            list: 'outlets/{outletId}/checks/{checkId}/extractCertainItems',
          },
          extractPartial: {
            list: 'outlets/{outletId}/checks/{checkId}/extractPartial',
          },
          status: {
            list: 'outlets/{outletId}/checks/{checkId}/status',
          },
          discount: {
            list: 'outlets/{outletId}/checks/{checkId}/discount',
          },
          mergeWithParent: {
            list: 'outlets/{outletId}/checks/{checkId}/mergeWithParent',
          },
        },
      },
      sales: {
        groups: {
          byId: {
            details: 'outlets/{outletId}/sales/groups/{groupId}',
          },
        },
      },
    },
  },
  menuTrees: {
    byId: {
      hierarchy: {
        list: 'outlets/{outletId}/menutrees/{menuTreeId}/hierarchy',
      },
      products: {
        list: 'outlets/{outletId}/menutrees/{menuTreeId}/products',
      },
    },
  },
  companies: {
    byId: {
      members: {
        byId: {
          details: 'companies/{companyId}/members/{memberId}',
          sendEmails: {
            list: 'companies/{companyId}/members/{memberId}/sendEmails',
          },
        },
      },
    },
    members: {
      verifyInvitation: {
        list: 'members/verifyInvitation',
      },
    },
  },
  customers: {
    orders: {
      byId: {
        receipt: {
          list: 'customers/orders/{orderId}/receipt',
          pdf: {
            list: 'customers/orders/{orderId}/receipt/pdf',
          },
        },
      },
      list: 'customers/orders',
      details: 'customers/orders/{orderId}',
    },
    checkouts: {
      list: 'customers/checkouts',
    },
    preOrderCheckouts: {
      list: 'customers/preOrders/checkouts',
    },
  },
  orders: {
    orderStatus: {
      byId: {
        details: 'orderStatus/{orderId}',
      },
    },
    byId: {
      messagingProviders: {
        list: 'orders/{orderId}/messagingProviders',
      },
    },
  },
  preOrderCheckouts: {
    byId: {
      summary: {
        list: 'checkouts/{checkoutId}/preOrders/summaryInfo',
      },
    },
  },
  penguin: {
    outlets: {
      byId: {
        stations: {
          list: 'penguin/outlets/{outletId}/stations',
          timeSlots: {
            list: 'penguin/outlets/{outletId}/stations/{stationId}/timeSlots',
          },
        },
      },
    },
  },
}

const card = {
  giftCards: {
    webView: {
      list: 'giftCards/webView',
    },
  },
}

const user = {
  customers: {
    details: 'customers/{customerId}',
    byId: {
      messagingProviders: {
        list: 'customers/{customerId}/messagingProviders',
        byId: {
          details:
            'customers/{customerId}/messagingProviders/{messagingProviderId}',
        },
      },
      addresses: {
        list: 'customers/{customerId}/addresses',
        byId: {
          details: 'customers/{customerId}/addresses/{addressId}',
        },
      },
      activate: {
        list: 'customers/{customerId}/activate',
      },
      anonymize: {
        list: 'customers/{customerId}/anonymize',
      },
    },
    register: {
      list: 'customers/register',
    },
  },
}

const archive = {
  logging: {
    list: 'logging',
  },
}

const endpoints = {
  media,
  core,
  card,
  user,
  archive,
}

export default endpoints
