import {
  APIGetRequest,
  APIPostRequest,
  APIPutRequest,
  CheckDetails,
  CheckDetailsOutletExtent,
  OrderFormat,
  PaymentMethodType,
  PaymentType,
  SalesLocationType,
  CheckAdyenCreateOnlinePaymentResponse,
  CheckAdyenSubmitOnlinePaymentDetailsResponse,
  CheckMetadataType,
} from '@ancon/wildcat-types'

import endpoints from '../endpoints'
import Request from '../../client/Request'
import { AdyenPaymentMethodsResponse } from '../../../features/adyen/types'

type ChecksAPI = {
  get: {
    details: APIGetRequest<
      { check: CheckDetails; outlet: CheckDetailsOutletExtent },
      { outletId: string; checkId: string }
    >
    onlinePaymentMethods: APIGetRequest<
      {
        paymentProviderData: AdyenPaymentMethodsResponse
        useBetaOnline: boolean
      },
      {
        outletId: string
        checkId: string
        paymentProviderData: string
        methodType: PaymentMethodType
      }
    >
    status: APIGetRequest<
      {
        status: number
      },
      { outletId: string; checkId: string }
    >
  }
  post: {
    create: APIPostRequest<
      { check: CheckDetails },
      {
        orderFormat: OrderFormat
        salesLocationId: string
        salesLocationType: SalesLocationType
      },
      { outletId: string }
    >
    createOnlinePayment: APIPostRequest<
      CheckAdyenCreateOnlinePaymentResponse,
      {
        paymentProviderData: string
        methodType: PaymentMethodType
        paymentType: PaymentType
        tipAmount?: number
      },
      { outletId: string; checkId: string }
    >
    onlinePaymentDetails: APIPostRequest<
      CheckAdyenSubmitOnlinePaymentDetailsResponse,
      { paymentProviderData: string },
      { outletId: string; checkId: string }
    >
  }
  put: {
    metadata: APIPutRequest<
      {
        check: CheckDetails
      },
      {
        type: CheckMetadataType
        reference: string
        metadata: string
      },
      {
        outletId: string
        checkId: string
      }
    >
    extractCertainItems: APIPutRequest<
      {
        originalCheck: CheckDetails
        mergeBackAt: string
        extractedCheck: CheckDetails
      },
      {
        extractCheckItems: Record<string, number>
      },
      {
        outletId: string
        checkId: string
      }
    >
    extractPartial: APIPutRequest<
      {
        originalCheck: CheckDetails
        mergeBackAt: string
        extractedCheck: CheckDetails
      },
      {
        parts: number
        extractParts: number
      },
      {
        outletId: string
        checkId: string
      }
    >
    mergeWithParent: APIPutRequest<
      {
        parentCheck: CheckDetails
      },
      {},
      {
        outletId: string
        checkId: string
      }
    >
    sendReceipt: APIPutRequest<
      {},
      { toEmail: string },
      { outletId: string; checkId: string; paymentId: string }
    >
    downloadReceipt: APIPutRequest<
      Blob,
      {},
      { outletId: string; checkId: string; paymentId: string }
    >
    discount: APIPutRequest<
      {},
      { code: string },
      { outletId: string; checkId: string }
    >
  }
}

const checks: ChecksAPI = {
  get: {
    details: new Request(endpoints.core.outlets.byId.checks.byId.details, {
      version: 1.4,
    }).get,
    onlinePaymentMethods: new Request(
      endpoints.core.outlets.byId.checks.byId.paymentMethods.online.list,
    ).get,
    status: new Request(endpoints.core.outlets.byId.checks.byId.status.list, {
      version: 1.4,
    }).get,
  },
  post: {
    create: new Request(endpoints.core.outlets.byId.checks.list, {
      version: 1.2,
    }).post,
    createOnlinePayment: new Request(
      endpoints.core.outlets.byId.checks.byId.payments.online.list,
      {
        version: 1.4,
      },
    ).post,
    onlinePaymentDetails: new Request(
      endpoints.core.outlets.byId.checks.byId.payments.online.details.list,
      {
        version: 1.4,
      },
    ).post,
  },
  put: {
    metadata: new Request(
      endpoints.core.outlets.byId.checks.byId.metadata.list,
      {
        version: 1.4,
      },
    ).put,
    extractCertainItems: new Request(
      endpoints.core.outlets.byId.checks.byId.extractCertainItems.list,
      {
        version: 1.4,
      },
    ).put,
    extractPartial: new Request(
      endpoints.core.outlets.byId.checks.byId.extractPartial.list,
      {
        version: 1.4,
      },
    ).put,
    mergeWithParent: new Request(
      endpoints.core.outlets.byId.checks.byId.mergeWithParent.list,
      {
        version: 1.4,
      },
    ).put,
    sendReceipt: new Request(
      endpoints.core.outlets.byId.checks.byId.payments.byId.sendReceipt.list,
      {
        version: 1.4,
      },
    ).put,
    downloadReceipt: new Request(
      endpoints.core.outlets.byId.checks.byId.payments.byId.downloadReceipt.list,
      {
        version: 1.4,
      },
    ).put,
    discount: new Request(endpoints.core.outlets.byId.checks.byId.discount.list)
      .put,
  },
}

export default checks
