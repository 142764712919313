import {
  CheckDetails,
  OrderFormat,
  OutletDetails,
  SaleGroupItem,
} from '@ancon/wildcat-types'
import { EntityState } from '@reduxjs/toolkit'

export type PayAndGoGroupParams = {
  outletId: string
  groupId: string
}

export type PayAndGoPaymentParams = {
  outletId: string
  checkId: string
  /** Used for receipts, resolves after payment completes */
  paymentId?: string
  /** Used when loaded from redirect result page */
  clientId?: string
}

export type PayAndGoReceiptParams = {
  outletId: string
  checkId: string
  paymentId: string
}

export enum PayAndGoPayMode {
  Full = 'full',
  SelectItems = 'selectItems',
  SplitEqually = 'splitEqually',
  CertainAmount = 'certainAmount',
}

export enum PayAndGoPayModeModalView {
  None = 'none',
  ChoosePayMode = 'choosePayMode',
  SplitEquallyNew = 'splitEquallyNew',
  CertainAmount = 'certainAmount',
}

export enum PayAndGoConfirmCancelModalView {
  None,
  ConfirmCancel,
}

export type PayAndGoReducerState = {
  virtualPOSClientId: string | null

  groupParams: PayAndGoGroupParams | null
  paymentParams: PayAndGoPaymentParams | null

  sessionId: string | null

  originalCheckDetails: CheckDetails | null
  /** Extracted or original check depending on whether you pay in full or split out/extracted */
  ownCheckDetails: CheckDetails | null
  /** Datetime string for the point at which the check is expired */
  mergeBackAt: string | null

  outletDetails: OutletDetails | null

  payMode: PayAndGoPayMode
  payModeModalView: PayAndGoPayModeModalView

  /** Own session metadata item selections */
  selectedItem: EntityState<PayAndGoSelectedItem>

  unpaidItem: EntityState<SaleGroupItem>
  ongoingItem: EntityState<SaleGroupItem>
  paidItem: EntityState<SaleGroupItem>

  splitItemModal: PayAndGoSplitItemModalState

  /** Used to track our own requests so that we can ignore our own events */
  requestIds: string[]

  // Initializing states
  isGroupInitializing: boolean
  isOutletInitializing: boolean

  confirmCancelModalView: PayAndGoConfirmCancelModalView
  isManualMergeBackInProgress: boolean
}

export type PayAndGoSplitItemModalState = {
  isOpen: boolean
  itemId: string | null
}

export type PayAndGoSelectedItem = {
  ids: string[]
  selectedQuantity: number
  parentId?: string | null
}

export type PayAndGoReservedItem = {
  ids: string[]
  reservedQuantity: number
}

export enum SubItemSelectionState {
  None,
  Some,
  All,
}

export type PayAndGoCartSummary = {
  discount: PayAndGoDiscount | null
  subtotalExclTaxAmount: string | null
  subtotalInclTaxAmount: string | null
  taxAmount: string | null
  tipAmount: string | null
  tipPercentage: number | null
  totalAmount: string
  totalItemsCount: string
  certainAmount: string | null
}

export type PayAndGoDiscount = {
  discountName: string
  discountAmount: string | null
}

export type PayAndGoOrderInfo = {
  orderNumber: string
  orderTime: string
  orderFormat: OrderFormat
}

export type PayAndGoSession = {
  sessionId: string
  modified: number
  selectedItems: PayAndGoSelectedItem[]
}

export enum PayAndGoCartMode {
  /** Use selected/available items for cart */
  ClientCalculated,
  /** Use check details for cart */
  ServerCalculated,
}

export enum PayAndGoAccordionKind {
  Unpaid,
  Paid,
}
