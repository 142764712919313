import {
  CheckDetails,
  CheckItem,
  CheckMetadataType,
  OutletDetails,
  SaleGroupItem,
} from '@ancon/wildcat-types'
import getTranslation from 'next-translate/getT'
import { v4 } from 'uuid'

import createAppAsyncThunk from '../../../store/createAppAsyncThunk'
import {
  applyCheckDiscount,
  downloadCheckReceipt,
  extractCertainCheckItems,
  extractPartialCheck,
  fetchCheckDetails,
  mergeCheckWithParent,
  sendCheckReceiptEmail,
  splitCheckItems,
  updateCheckMetadata,
} from '../../check/store/checkThunks'
import api from '../../../api'
import { appShowError } from '../../app/store/appSlice'
import getTranslatedError from '../../app/utils/getTranslatedError'
import { appLanguageSelector } from '../../app/store/appSelectors'
import { PayAndGoSelectedItem } from '../types'
import updatePayAndGoSession from '../utils/updatePayAndGoSession'

import {
  payAndGoOriginalCheckDetailsSelector,
  payAndGoGroupParamsSelector,
  payAndGoSelectedItemsSelector,
  payAndGoSessionIdSelector,
  payAndGoSplitItemModalItemIdSelector,
  payAndGoVirtualPOSClientIdSelector,
  payAndGoUnpaidItemsSelector,
  payAndGoPaymentParamsSelector,
  payAndGoOwnCheckDetailsSelector,
} from './payAndGoSelectors'

export const fetchPayAndGoGroup = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    unpaidItems: SaleGroupItem[]
    ongoingItems: SaleGroupItem[]
    paidItems: SaleGroupItem[]
    virtualPos: { id: string } | null
  },
  {
    outletId: string
    groupId: string
  }
>('payAndGo/fetchPayAndGoGroup', async ({ outletId, groupId }) => {
  const response = await api.core.salesGroups.get.details({
    outletId,
    groupId,
    'extent.virtualPos': true,
  })

  return response.data
})

export const fetchOwnCheckDetails = createAppAsyncThunk<
  CheckDetails,
  {
    outletId: string
    checkId: string
  }
>(
  'payAndGo/fetchOwnCheckDetails',
  async ({ outletId, checkId }, { getState, dispatch }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!

    const data = await dispatch(
      fetchCheckDetails({ virtualPOSClientId, outletId, checkId }),
    ).unwrap()

    return data.check
  },
)

export const fetchPayAndGoOutlet = createAppAsyncThunk<
  OutletDetails,
  { outletId: string }
>('payAndGo/fetchPayAndGoOutlet', async ({ outletId }) => {
  const response = await api.core.outlet.get.details({
    outletId,
  })

  return response.data
})

export const splitPayAndGoCheckItems = createAppAsyncThunk<
  {
    created: CheckItem[]
    updated: CheckItem[]
  } | null,
  {
    parts: number
  }
>(
  'payAndGo/splitPayAndGoCheckItems',
  async ({ parts }, { getState, dispatch }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!

    const params = payAndGoGroupParamsSelector(getState())

    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

    const itemId = payAndGoSplitItemModalItemIdSelector(getState())

    if (!params || !originalCheck || !itemId) {
      return null
    }

    try {
      const data = await dispatch(
        splitCheckItems({
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          parts,
          checkItemIds: [itemId],
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
)

export const updateSelectedPayAndGoItemQuantity = createAppAsyncThunk<
  CheckDetails | null,
  {
    prevItem: PayAndGoSelectedItem | null
    nextItem: PayAndGoSelectedItem | null
  }
>(
  'payAndGo/updateSelectedPayAndGoItemQuantity',
  async (_, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const sessionId = payAndGoSessionIdSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())
    const selectedItems = payAndGoSelectedItemsSelector(getState())

    if (!params || !sessionId || !originalCheck) {
      return null
    }

    const session = updatePayAndGoSession(originalCheck, sessionId, () => ({
      sessionId,
      modified: new Date().valueOf(),
      selectedItems,
    }))

    const data = await dispatch(
      updateCheckMetadata({
        correlationId: requestId,
        virtualPOSClientId,
        outletId: params.outletId,
        checkId: originalCheck.id,
        type: CheckMetadataType.PayAndGoClientItemSelection,
        reference: sessionId,
        metadata: JSON.stringify(session),
      }),
    ).unwrap()

    return data.check
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const clearAllPayAndGoItemSelections =
  createAppAsyncThunk<CheckDetails | null>(
    'payAndGo/clearAllPayAndGoItemSelections',
    async (_, { getState, dispatch, requestId }) => {
      const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
      const params = payAndGoGroupParamsSelector(getState())
      const sessionId = payAndGoSessionIdSelector(getState())
      const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

      if (!params || !sessionId || !originalCheck) {
        return null
      }

      const session = updatePayAndGoSession(originalCheck, sessionId, () => ({
        sessionId,
        modified: new Date().valueOf(),
        selectedItems: [],
      }))

      const data = await dispatch(
        updateCheckMetadata({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          type: CheckMetadataType.PayAndGoClientItemSelection,
          reference: sessionId,
          metadata: JSON.stringify(session),
        }),
      ).unwrap()

      return data.check
    },
    {
      idGenerator() {
        return v4()
      },
    },
  )

export const extractSelectedPayAndGoCheckItems = createAppAsyncThunk<{
  originalCheck: CheckDetails
  mergeBackAt: string
  extractedCheck: CheckDetails
} | null>(
  'payAndGo/extractSelectedPayAndGoCheckItems',
  async (_, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())
    const selectedItems = payAndGoSelectedItemsSelector(getState())

    if (
      !params ||
      !originalCheck ||
      !selectedItems ||
      selectedItems.length < 1
    ) {
      return null
    }

    const extractCheckItems = selectedItems.reduce<Record<string, number>>(
      (acc, item) => {
        const checkItem = originalCheck.items.find(i => item.ids.includes(i.id))

        if (checkItem != null && item.selectedQuantity > 0) {
          acc[checkItem.id] = item.selectedQuantity
        }

        return acc
      },
      {},
    )

    try {
      await dispatch(clearAllPayAndGoItemSelections())

      const data = await dispatch(
        extractCertainCheckItems({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          extractCheckItems,
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const extractUnpaidPayAndGoCheckItems = createAppAsyncThunk<{
  originalCheck: CheckDetails
  mergeBackAt: string
  extractedCheck: CheckDetails
} | null>(
  'payAndGo/extractUnpaidPayAndGoCheckItems',
  async (_, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())
    const unpaidItems = payAndGoUnpaidItemsSelector(getState())
    if (!params || !originalCheck || !unpaidItems || unpaidItems.length < 1) {
      return null
    }

    const extractCheckItems = unpaidItems.reduce<Record<string, number>>(
      (acc, item) => {
        const checkItem = originalCheck.items.find(i => item.ids.includes(i.id))

        if (checkItem != null && item.quantity > 0) {
          acc[checkItem.id] = item.quantity
        }

        return acc
      },
      {},
    )

    try {
      await dispatch(clearAllPayAndGoItemSelections())

      const data = await dispatch(
        extractCertainCheckItems({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          extractCheckItems,
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const extractPartialPayAndGoCheck = createAppAsyncThunk<
  {
    originalCheck: CheckDetails
    mergeBackAt: string
    extractedCheck: CheckDetails
  } | null,
  { parts: number; extractParts: number }
>(
  'payAndGo/extractPartialPayAndGoCheck',
  async ({ parts, extractParts }, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

    if (!params || !originalCheck) {
      return null
    }

    try {
      const data = await dispatch(
        extractPartialCheck({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          parts,
          extractParts,
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

// MARK: Receipts

export const sendPayAndGoReceipt = createAppAsyncThunk<
  void,
  { outletId: string; checkId: string; paymentId: string; email: string }
>(
  'payAndGo/sendPayAndGoReceipt',
  async (
    { outletId, checkId, paymentId, email },
    { getState, dispatch, requestId },
  ) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!

    try {
      await dispatch(
        sendCheckReceiptEmail({
          correlationId: requestId,
          virtualPOSClientId,
          outletId,
          checkId,
          paymentId,
          email,
        }),
      ).unwrap()
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const downloadPayAndGoReceipt = createAppAsyncThunk<
  Blob | null,
  { outletId: string; checkId: string; paymentId: string }
>(
  'payAndGo/downloadPayAndGoReceipt',
  async (
    { outletId, checkId, paymentId },
    { getState, dispatch, requestId },
  ) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!

    try {
      const data = await dispatch(
        downloadCheckReceipt({
          correlationId: requestId,
          virtualPOSClientId,
          outletId,
          checkId,
          paymentId,
        }),
      ).unwrap()

      return data
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      dispatch(appShowError(getTranslatedError(err, t)))
      throw err
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const applyPayAndGoCheckDiscount = createAppAsyncThunk<
  void,
  { discountCode: string }
>(
  'payAndGo/applyPayAndGoCheckDiscount',
  async (
    { discountCode },
    { getState, dispatch, requestId, rejectWithValue },
  ) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoGroupParamsSelector(getState())
    const originalCheck = payAndGoOriginalCheckDetailsSelector(getState())

    if (!params || !originalCheck) {
      return undefined
    }

    try {
      await dispatch(
        applyCheckDiscount({
          correlationId: requestId,
          virtualPOSClientId,
          outletId: params.outletId,
          checkId: originalCheck.id,
          discountCode,
        }),
      ).unwrap()
      return undefined
    } catch (err) {
      const locale = appLanguageSelector(getState())
      const t = await getTranslation(locale, 'common')
      const discountError = getTranslatedError(err, t, {
        title: t('errors.discountGenericError.title'),
        message: t('errors.discountGenericError.message'),
      })
      return rejectWithValue(discountError)
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)

export const mergeBackExtractedPayAndGoCheck = createAppAsyncThunk<
  CheckDetails | null,
  void
>(
  'payAndGo/mergeBackExtractedPayAndGoCheck',
  async (_, { getState, dispatch, requestId }) => {
    const virtualPOSClientId = payAndGoVirtualPOSClientIdSelector(getState())!
    const params = payAndGoPaymentParamsSelector(getState())
    const ownCheck = payAndGoOwnCheckDetailsSelector(getState())

    if (!params || !ownCheck) {
      return null
    }

    try {
      const data = await dispatch(
        mergeCheckWithParent({
          correlationId: requestId,
          outletId: params.outletId,
          checkId: ownCheck.id,
          virtualPOSClientId,
        }),
      ).unwrap()

      return data.parentCheck
    } catch {
      // Ignore error
      return null
    }
  },
  {
    idGenerator() {
      return v4()
    },
  },
)
